import axios from "axios";


const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
//onst baseUrl = `http://localhost:2054/`;

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.getItem("token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const { response } = error;
    
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 401
    ) {
      //let lockModal = document.getElementsByClassName("unlockModalOpen");
      
      localStorage.clear();
      const is_root = window.location.pathname === "/login";
      if (!is_root) {
        window.location.href = "/login";
      }
      // if (
      //   !window.location.href.includes("/login") &&
      //   !window.location.href.includes("/packleader-admin") &&
      //   isEmpty(lockModal)
      // ) {
      //   const loginUrl = localStorage.getItem("loginUrl");
      //   window.location.href =
      //     "/" +
      //     (loginUrl == "null" || loginUrl == undefined || loginUrl == null
      //       ? "login"
      //       : loginUrl);
      // }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);

export const get = (url, params) =>
  axios
    .get(baseUrl + url, { params })
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const post = (url, payload, config = {}) =>
  axios
    .post(baseUrl + url, payload, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleError(error);
    });

export const put = (url, payload, config = {}) =>
  axios
    .put(baseUrl + url, payload, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleError(error);
    });

export const handleError = (error) => {
  const { response } = error;
  if (error && response && response.data.message) {
    throw response.data.message;
  } else if (response.data.errorMessage) {
    if (response.data && response.data.code && response.data.code === 409) {
      //Toast.toastError(response.data.errorMessage);
    } else if (
      response.data &&
      response.data.code &&
      response.data.code === 400
    ) {
      //Toast.toastError(response.data.errorMessage);
    } else {
      throw response.data.errorMessage;
    }
  }
  throw error;
};
