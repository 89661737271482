import * as network from "./network";

const fetchOffer = () => {
  return network.get(`admin/offer`);
};

const createOffer = (data = {}) => {
  return network.post(`admin/offer`, data);
};

const updateOffer = (data = {}) => {
  return network.put(`admin/offer`, data);
};

export default {
  fetchOffer,
  createOffer,
  updateOffer,
};
