export const logoNegative = [
  "608 134",
  `
<title>Cottage Grilllllll</title>
<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 154.1 56" style="enable-background:new 0 0 154.1 56;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#CC7B29;}
	.st1{fill:#6E8752;}
	.st2{fill:#95424D;}
	.st3{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M86.8,12.2c0,0.3,0,0.5-0.1,0.8c-0.1,0.8-0.5,1.4-1,2c-0.3,0.3-0.7,0.7-1,0.9c-0.6,0.4-1.2,0.8-1.9,1
		c-0.9,0.4-1.8,0.6-2.7,0.8c-0.7,0.1-1.5,0.2-2.2,0.3c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.5-0.1-2.2-0.4c-0.7-0.3-1.3-0.7-1.9-1.2
		c-0.8-0.7-1.4-1.6-2-2.5c-0.2-0.4-0.5-0.9-0.7-1.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.3-0.3-0.7-0.4-1.1c-0.2-0.5-0.2-0.6-0.8-0.6
		c-2.3,0-4.6,0-7,0c-0.2,0-0.5,0.1-0.5-0.2c0.1,0,0.2,0,0.2,0c1.2,0,2.5,0,3.7,0c0.6,0,1.2,0,1.8,0c0.7,0,1.3,0,2,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3c-0.1-0.6,0-1.2,0.2-1.8c0.2-0.4,0.4-0.7,0.7-1
		c0.4-0.4,0.9-0.8,1.4-1.1c0.8-0.4,1.6-0.7,2.4-0.9c0.5-0.1,1-0.2,1.6-0.3C77.3,5,78.2,5,79,5c1.7,0.1,3.3,0.7,4.7,1.8
		c0.8,0.6,1.5,1.4,2,2.3c0.3,0.4,0.5,0.9,0.7,1.4c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
		c-0.3,0.1-0.3,0.4-0.2,0.7C86.8,11.6,87,11.9,86.8,12.2z"/>
	<path class="st1" d="M89.9,10.7c0.1-1.3,0.5-2.6,1-3.8c0.3-0.6,0.6-1.3,1-1.8c0.2-0.3,0.5-0.6,0.7-0.9c0.4-0.4,0.8-0.6,1.3-0.8
		c0.3-0.1,0.5-0.1,0.8-0.1c0.8,0,1.5,0.1,2.2,0.4c1,0.3,1.9,0.9,2.7,1.5c1.1,0.8,2,1.7,2.7,2.8c0.5,0.7,0.9,1.6,1.2,2.4
		c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c1,0,2.1,0,3.1,0c0.5,0,1.1,0,1.6,0c0.1,0.1,0,0.2-0.1,0.2
		c-1.4,0-2.8,0-4.3,0c-0.4,0-0.4,0-0.4,0.5c0,0.3,0.2,0.5,0,0.8c0,0.4,0,0.8,0,1.3c0,0.5-0.1,1.1-0.3,1.6c-0.2,0.7-0.4,1.3-0.7,1.9
		c-0.2,0.4-0.5,0.9-0.9,1.2c-0.5,0.5-1.1,0.8-1.9,1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.8,0-1.5-0.1-2.2-0.3c-0.4-0.1-0.8-0.3-1.2-0.5
		c-1.2-0.6-2.3-1.3-3.4-2.1c-0.5-0.4-0.9-0.8-1.3-1.2c-0.6-0.7-1-1.5-1.2-2.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.1
		c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.2,0c0-0.2,0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.5,0c0.5,0,0.4,0,0.4-0.5
		C89.8,11.3,89.7,11,89.9,10.7z"/>
	<path class="st2" d="M108.7,10.9c0,0,0-0.1,0-0.1c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.5-0.8,0.7-1.3c0.3-0.5,0.6-1.1,1-1.5
		c0.6-0.8,1.4-1.5,2.3-2c0.8-0.5,1.6-0.9,2.5-1.1c0.3-0.1,0.5-0.1,0.8-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0
		c0.7,0,1.5,0.1,2.2,0.4c0.7,0.4,1.3,0.9,1.8,1.6c0.3,0.5,0.6,1.1,0.7,1.7c0.1,0.4,0.2,0.7,0.2,1.1c0,0.3,0.1,0.6,0.1,0.9
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.3,0,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.4,0.1-0.5,0.4-0.5,0.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4,0c1.6,0,3.2,0,4.7,0c0.2,0,0.5-0.1,0.6,0.1c-0.1,0-0.1,0-0.2,0
		c-0.5,0-1,0-1.6,0c-1.1,0-2.2,0-3.3,0c-0.3,0-0.6,0-1,0c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.5-0.4,1-0.6,1.5c-0.3,0.5-0.6,1-0.9,1.4
		c-0.5,0.6-1.1,1.2-1.9,1.6c-0.8,0.5-1.6,0.8-2.5,1c-0.4,0.1-0.7,0.1-1.1,0.2c-0.8,0.1-1.6,0.1-2.4,0c-0.8-0.1-1.6-0.2-2.4-0.4
		c-0.5-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.7-0.6-0.9-1c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.6,0.1-1.1,0.2-1.6c0.1-0.4,0.3-0.8,0.4-1.1
		c0.1-0.1,0.1-0.3,0.2-0.4C108.1,11.7,108.3,11.2,108.7,10.9z"/>
	<path d="M123.5,29.7c0-1.3,0-2.6,0-3.9c0-0.2,0-0.4,0-0.5c0-0.4-0.1-0.8-0.3-1.1c-0.1-0.2-0.2-0.3-0.3-0.4
		c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0,0,0.1,0,0.1
		c0,0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.3-0.6-0.5-1-0.7c-0.8-0.5-1.6-0.8-2.5-0.9c-0.6,0-1.2,0-1.8,0.1
		c-0.8,0.1-1.5,0.3-2.2,0.7c-0.8,0.4-1.5,1-2.1,1.6c-1.2,1.3-1.8,2.7-1.9,4.5c0,0.3,0,0.5,0,0.8c0,0.8,0.2,1.6,0.6,2.4
		c0.7,1.5,1.7,2.6,3.1,3.4c0.8,0.5,1.6,0.7,2.5,0.9c0.8,0.1,1.6,0.1,2.3,0c0.7-0.1,1.3-0.4,1.8-0.8c0.1-0.1,0.3-0.2,0.4-0.3
		c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3,0,0.5-0.1,0.8c-0.2,0.7-0.4,1.2-0.9,1.7
		c-0.2,0.2-0.5,0.4-0.8,0.6c-0.4,0.2-0.8,0.3-1.3,0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7-0.2-1.2-0.5-1.8-0.9
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.2
		c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.2,0.5,0,0.8c0.2,0.3,0.5,0.6,0.8,0.9c0.8,0.8,1.8,1.3,2.9,1.7c0.5,0.2,1.1,0.2,1.7,0.3
		c0.7,0,1.4,0,2.1-0.2c1.2-0.3,2.2-0.8,3.2-1.6c0.6-0.5,1.1-1.1,1.4-1.8c0.1-0.1,0.1-0.2,0.2-0.3c0.6-1.5,0.9-3,0.9-4.6
		C123.6,32.1,123.5,30.9,123.5,29.7z M120.9,30.9c-0.1,0.6-0.4,1.2-0.8,1.7c-0.4,0.5-0.8,0.9-1.4,1.2c-0.9,0.6-2,0.8-3.1,0.6
		c-1-0.1-1.8-0.6-2.5-1.3c-0.6-0.6-1.1-1.3-1.3-2.1c-0.2-0.7-0.2-1.5-0.1-2.2c0.1-0.6,0.2-1.1,0.5-1.6c0.6-1,1.3-1.7,2.4-2.2
		c0.4-0.2,0.9-0.3,1.4-0.3c0.5,0,1,0,1.5,0.1c0.6,0.2,1.1,0.4,1.5,0.8c0.5,0.4,0.9,0.8,1.2,1.3c0.3,0.4,0.5,0.9,0.6,1.4
		c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.4,0,0.6C120.9,30.1,120.9,30.5,120.9,30.9z"/>
	<path d="M84.4,29.8c0-1.3,0-2.7,0-4c0-0.2,0-0.4,0-0.6c0-0.4-0.2-0.8-0.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.3,0-0.6,0-0.8,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0
		c-0.1,0-0.2-0.1-0.4-0.2c-0.3-0.3-0.6-0.5-1-0.7c-0.8-0.6-1.7-0.8-2.6-0.9c-0.6,0-1.1,0-1.7,0.1c-1,0.2-2,0.5-2.8,1.1
		c-0.9,0.6-1.6,1.3-2.2,2.2c-0.6,1-1,2-1.1,3.1c0,0.6,0,1.1,0.1,1.7c0.1,0.8,0.4,1.5,0.7,2.2c0.4,0.7,0.8,1.3,1.4,1.8
		c1.1,1.1,2.5,1.8,4,2c0.8,0.1,1.5,0.1,2.3,0c0.7-0.1,1.3-0.4,1.8-0.7c0.1-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.4-0.2
		c0.1,0,0.2,0,0.3,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.8-0.3,1.2c-0.1,0.3-0.3,0.6-0.6,0.9
		c-0.4,0.5-0.9,0.8-1.5,1c-0.8,0.3-1.7,0.3-2.6,0.2c0,0,0,0,0,0c-0.9-0.1-1.7-0.5-2.4-1.2c-0.2-0.2-0.3-0.3-0.5-0.5
		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0.1-0.7,0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.1,0.2,0.3,0.3,0.4c1,1.1,2.1,1.8,3.5,2.2c1.1,0.3,2.2,0.3,3.3,0.1c1.2-0.2,2.2-0.7,3.1-1.4
		c0.8-0.6,1.5-1.4,1.9-2.4c0.6-1.5,0.9-3,0.9-4.6C84.4,32.1,84.4,31,84.4,29.8z M81.8,29.5c0,0.6,0,1.1-0.1,1.5
		c-0.1,0.6-0.4,1.2-0.8,1.7c-0.4,0.5-0.9,0.9-1.5,1.3c-0.6,0.4-1.3,0.6-2.1,0.6c-1.3,0-2.4-0.4-3.4-1.3c-0.6-0.6-1-1.3-1.3-2.1
		c-0.2-0.7-0.2-1.3-0.2-2c0-0.3,0.1-0.7,0.2-1c0.5-1.5,1.8-2.7,3-3.1c0.4-0.1,0.8-0.2,1.2-0.2c0.4,0,0.8,0,1.3,0.1
		c0.6,0.1,1.1,0.4,1.6,0.7c0.5,0.4,0.9,0.8,1.3,1.3c0.4,0.5,0.6,1.1,0.6,1.7C81.8,28.9,81.8,29.3,81.8,29.5z"/>
	<path d="M100,27.7c0-0.1-0.1-0.2-0.1-0.4c-0.3-1.1-0.9-2-1.7-2.8c-1.2-1.2-2.7-1.9-4.4-2c-0.6-0.1-1.3,0-1.9,0.1
		c-0.9,0.1-1.7,0.4-2.5,0.9c-0.7,0.4-1.4,1-1.9,1.6c-0.5,0.6-0.9,1.2-1.2,2c-0.5,1.2-0.6,2.4-0.4,3.7c0.1,0.9,0.4,1.7,0.9,2.5
		c0.4,0.6,0.8,1.2,1.4,1.7c1.3,1.2,2.8,1.8,4.6,1.9c0.4,0,0.9,0,1.3-0.1c0.9-0.1,1.8-0.4,2.7-0.9c0.9-0.5,1.6-1.2,2.2-1.9
		c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.3-0.3-0.5-0.5-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.1-0.6-0.1-1-0.1
		c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.3,0.3-0.4,0.4c-0.8,0.8-1.8,1.3-2.9,1.3c-0.6,0-1.3,0-1.9-0.2c-0.8-0.3-1.5-0.8-2-1.4
		c-0.7-0.9-1.1-1.9-1.1-3c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.3,0,0.7,0,1,0c1.3,0,2.5,0,3.8,0c1.5,0,2.9,0,4.4,0
		c0.2,0,0.4,0,0.5,0c0.6-0.1,1.1-0.4,1.5-0.9C100,28.3,100.1,28,100,27.7z M96.4,26.9c-0.1,0.2-0.4,0.3-0.6,0.4
		c-0.2,0.1-0.4,0.1-0.7,0.1c-0.7,0-1.3,0-2,0c-0.7,0-1.3,0-2,0c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.5-0.2-0.6-0.4c0,0,0,0,0-0.1
		c-0.2-0.3-0.2-0.6,0-0.8c0.1-0.1,0.2-0.3,0.4-0.4c0.4-0.3,0.9-0.5,1.4-0.6c0.4-0.1,0.8-0.2,1.3-0.2c0.5,0,1,0,1.5,0.1
		c0.6,0.1,1.2,0.3,1.7,0.7c0.1,0.1,0.2,0.2,0.3,0.3C96.7,26.2,96.7,26.5,96.4,26.9z"/>
	<path d="M68.3,25.8c0-0.2,0-0.4,0-0.7c0-0.4-0.2-0.7-0.4-1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.4-0.2-0.6-0.2
		c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.6,0.3-0.6,0.7c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.5-0.4-0.8-0.6
		c-0.8-0.7-1.8-1-2.8-1.1c-0.6,0-1.1,0-1.7,0.1c-0.7,0.1-1.4,0.3-2.1,0.6c-1.2,0.6-2.1,1.4-2.9,2.5c-0.4,0.6-0.7,1.3-0.9,2
		c-0.2,0.7-0.3,1.5-0.3,2.2c0,1,0.3,2,0.7,2.9c0.4,0.8,1,1.5,1.6,2.1c0.7,0.6,1.5,1.2,2.4,1.5c1.1,0.4,2.3,0.5,3.5,0.4
		c0.8-0.1,1.6-0.4,2.3-0.9c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3,0,0.3,0.1
		c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.5,0.3,0.6c0.3,0.3,0.6,0.4,1,0.4c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.2,0.3-0.4,0.3-0.7c0.1-0.4,0.1-0.7,0.1-1.1c0-1.4,0-2.8,0-4.2C68.3,28.6,68.3,27.2,68.3,25.8z M65.6,31.2
		c-0.1,0.4-0.2,0.7-0.4,1c-0.5,0.8-1.2,1.5-2.1,1.9c-0.7,0.3-1.5,0.4-2.2,0.4c-2-0.1-3.6-1.7-4.1-3.2c-0.1-0.4-0.2-0.8-0.2-1.3
		c0-0.6,0-1.2,0.2-1.8c0.1-0.4,0.2-0.7,0.4-1c0.6-1,1.3-1.7,2.4-2.1c0.5-0.2,1-0.3,1.5-0.3c0.6,0,1.2,0,1.8,0.3
		c0.2,0.1,0.3,0.1,0.5,0.2c0.8,0.4,1.5,1,2,1.8c0.3,0.4,0.4,0.9,0.5,1.4c0.1,0.4,0.1,0.8,0.1,1.2C65.8,30.2,65.7,30.7,65.6,31.2z"/>
	<path d="M35.7,28.9c0-0.6-0.2-1.2-0.4-1.8c-0.4-1-0.9-1.8-1.7-2.6c-1.2-1.2-2.7-1.9-4.4-2c-0.7-0.1-1.3,0-2,0c-1,0.1-2,0.5-2.8,1
		c-0.6,0.4-1,0.8-1.5,1.3c-0.8,0.9-1.3,1.8-1.6,2.9c-0.2,0.6-0.2,1.3-0.2,2c0,1,0.2,1.9,0.6,2.8c0.4,0.8,1,1.6,1.6,2.2
		c1.3,1.2,2.9,1.9,4.7,2c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.2,1.7-0.4c1.1-0.4,2.1-1.1,2.9-2c1.2-1.3,1.8-2.9,1.8-4.6
		C35.7,29.4,35.7,29.2,35.7,28.9z M33.1,30c0,0.6-0.1,1.1-0.4,1.6c-0.5,1.2-1.7,2.3-3,2.7c-0.5,0.2-1.1,0.2-1.7,0.2
		c-1.9-0.1-3.6-1.6-4.1-3.4c-0.1-0.4-0.2-1-0.2-1.4c0-0.8,0.1-1.5,0.4-2.2c0.6-1.2,1.5-2.1,2.8-2.6c1.5-0.5,2.9-0.3,4.1,0.7
		c0.8,0.6,1.4,1.4,1.7,2.3C33.1,28.6,33.1,29.3,33.1,30z"/>
	<path d="M7.1,29.6c0-1.5,0.4-2.8,1.3-4c0.5-0.6,1-1.2,1.6-1.6c0.6-0.5,1.3-0.8,2-1.1c0.5-0.2,1-0.3,1.6-0.3c0.6-0.1,1.3,0,1.9,0
		c1.5,0.2,2.8,0.8,3.9,1.8c0.2,0.2,0.5,0.5,0.7,0.7c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.2,0.5,0,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
		c-0.5,0.2-0.9,0.3-1.5,0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.7-1.4-1.2-2.4-1.4c-1.4-0.3-2.6-0.1-3.7,0.8
		c-0.6,0.4-1.1,1-1.4,1.6C10,28,9.8,28.6,9.8,29.4c0,0.5,0,1.1,0.2,1.6c0.1,0.5,0.3,1,0.6,1.4c0.5,0.7,1.1,1.3,1.9,1.7
		c0.8,0.4,1.6,0.5,2.5,0.4c1.2-0.1,2.1-0.7,2.9-1.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.5,0,1,0.1,1.4,0.3
		c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.2-0.3,0.4-0.5,0.6c-0.9,0.9-1.9,1.6-3.2,2c-1,0.3-2,0.4-3,0.2
		c-0.5-0.1-1.1-0.2-1.6-0.3c-0.8-0.3-1.5-0.6-2.1-1.1c-0.6-0.5-1.1-1-1.6-1.6c-0.6-0.9-1-1.8-1.1-2.9c0-0.1,0-0.3-0.1-0.4
		C7.1,30.1,7.1,29.9,7.1,29.6z"/>
	<path d="M38.6,30.6c0-1.2,0-2.4,0-3.5c0-0.3,0-0.6-0.1-0.9c-0.1-0.4-0.2-0.7-0.4-1.1c-0.1-0.2-0.2-0.3-0.4-0.5
		c-0.2-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3-0.1-0.6-0.3-0.8-0.6c-0.3-0.4-0.3-1,0.1-1.4c0.2-0.2,0.5-0.4,0.8-0.5
		c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.6-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.3-0.7,0.4-1.1c0.1-0.3,0.1-0.6,0.1-0.9
		c0-0.3,0-0.6,0-0.9c0-0.6,0.2-1.1,0.4-1.6c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.1,0.6,0,0.9,0.2
		c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.3,0.3,0.7,0.4,1c0.1,0.3,0.1,0.6,0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.5,0.1,0.9,0.3,1.3
		c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3,0c0.4,0.1,0.7,0.3,0.9,0.6c0.3,0.5,0.2,1-0.1,1.4
		c-0.2,0.3-0.6,0.5-1,0.5c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.3,0.3-0.4,0.5c-0.2,0.4-0.3,0.8-0.3,1.2
		c0,0.2,0,0.5,0,0.7c0,2.4,0,4.7,0,7.1c0,0.4,0,0.8-0.1,1.1c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.2-0.2,0.3-0.4,0.4
		c-0.4,0.3-0.8,0.3-1.2,0c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.4-0.4-0.9-0.5-1.5c0-0.2,0-0.3,0-0.5C38.6,33,38.6,31.8,38.6,30.6z"/>
	<path d="M49.5,36.8c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.4-0.4-0.8-0.4-1.3c0-0.3,0-0.5,0-0.8c0-2.3,0-4.7,0-7
		c0-0.3,0-0.5-0.1-0.8c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.3c-0.1,0-0.2,0-0.4,0
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.3-0.5-0.8-0.4-1.2c0-0.1,0-0.1,0.1-0.2c0.2-0.4,0.5-0.7,0.9-0.8c0.1,0,0.3,0,0.4-0.1
		c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.4,0.3-0.7,0.4-1.1c0-0.3,0.1-0.6,0.1-0.8c0-0.4,0-0.8,0-1.2
		c0-0.5,0.2-0.9,0.4-1.3c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.9-0.4,1.3,0c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.4,0.4,0.8,0.5,1.2
		c0,0.3,0.1,0.6,0.1,0.8c0,0.4,0,0.8,0,1.1c0,0.4,0.1,0.7,0.3,1.1c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.3
		c0.1,0,0.3,0,0.4,0c0.4,0.1,0.7,0.3,0.9,0.6c0.2,0.4,0.2,0.9-0.1,1.3c-0.2,0.3-0.6,0.5-1,0.6c-0.1,0-0.1,0-0.2,0
		c-0.3,0-0.5,0.1-0.6,0.3c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.4-0.3,0.7-0.3,1.1c0,0.3,0,0.5,0,0.8c0,2.3,0,4.7,0,7
		c0,0.3,0,0.6-0.1,0.9c-0.1,0.5-0.2,0.9-0.5,1.3c-0.1,0.1-0.2,0.3-0.3,0.4C50,36.7,49.7,36.8,49.5,36.8z"/>
	<path d="M141.2,26.3c0,2.6,0,5.3,0,7.9c0,0.2,0,0.5,0,0.7c0,0.4-0.1,0.7-0.3,1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.4,0.3-0.8,0.2-1.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.3-0.4-0.4-0.8-0.4-1.3c0-0.1,0-0.3,0-0.4c0-5.4,0-10.8,0-16.3
		c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.8-0.2,1.1,0c0.2,0.1,0.3,0.3,0.4,0.5
		c0.2,0.3,0.3,0.7,0.3,1.1c0,0.2,0,0.4,0,0.7C141.2,21,141.2,23.7,141.2,26.3z"/>
	<path d="M143.3,26.3c0-2.7,0-5.4,0-8c0-0.2,0-0.4,0-0.7c0-0.4,0.2-0.8,0.4-1.1c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.2,0.7-0.3,1-0.1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.2,0.7,0.2,1c0,0.1,0,0.1,0,0.2c0,5.4,0,10.8,0,16.3
		c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.2-0.2,0.4-0.4,0.5c-0.4,0.3-0.8,0.3-1.2,0c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.2,0-0.4,0-0.7C143.3,31.7,143.3,29,143.3,26.3z"/>
	<path d="M125.6,29.6c0-1.6,0-3.2,0-4.7c0-0.3,0-0.6,0.1-1c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.2,0.4-0.2,0.7-0.2c0.3,0,0.5,0,0.8,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.1,0,0.2
		c0,0.2,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.4-0.3,0.8-0.4,1.3-0.3c0.2,0,0.4,0.1,0.5,0.2
		c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-0.4,0.2-0.8,0.5-1.1,0.8
		c-0.6,0.6-1,1.3-1.1,2.1c-0.1,0.5-0.1,0.9-0.1,1.4c0,1.6,0,3.2,0,4.9c0,0.2,0,0.4,0,0.7c0,0.4-0.2,0.8-0.4,1.2
		c-0.1,0.2-0.2,0.3-0.4,0.4c-0.4,0.3-0.8,0.3-1.2,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.4-0.4-0.8-0.5-1.3c0-0.2,0-0.4,0-0.6
		C125.6,32.8,125.6,31.2,125.6,29.6z"/>
	<path d="M133.3,29.2c0-1.7,0-3.3,0-5c0-0.3,0-0.7,0.1-1c0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.4,1-0.4,1.4,0
		c0.1,0.1,0.3,0.3,0.4,0.5c0.2,0.4,0.4,0.8,0.4,1.3c0,0.2,0,0.4,0,0.6c0,3.3,0,6.6,0,9.8c0,0.4,0,0.7-0.1,1.1
		c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.2,0.3-0.4,0.4c-0.4,0.3-0.8,0.3-1.2,0c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.3-0.4-0.6-0.4-1
		c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.3,0-0.4C133.3,32.4,133.3,30.8,133.3,29.2z"/>
	<path d="M62,10.6C62.1,10.6,62.1,10.6,62,10.6c0.1,0,0.2,0,0.2,0c1.2,0,2.3,0,3.5,0c1.4,0,2.8,0,4.2,0c0.1,0,0.1,0,0.2,0
		c0.1,0,0.1,0,0.1,0.1c0.1,0.5,0.3,0.9,0.4,1.4c0,0.1,0,0.2,0,0.2c-0.4,0-0.8,0-1.3,0c-0.9,0-1.8,0-2.6,0c-0.8,0-1.6,0-2.4,0
		c-0.4,0-0.7,0-1.1,0c-1.1,0-2.3,0-3.4,0c-0.3,0-0.6,0-1-0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.5-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.6-0.3
		c0.2-0.1,0.3-0.1,0.5-0.2c0.3-0.2,0.7-0.2,1.1-0.2C60.6,10.6,61.3,10.6,62,10.6z"/>
	<path d="M104.8,49.4c0-0.9,0-1.8,0-2.7c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0,0.3,0.1
		c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.5,0.7,1,1.1,1.5c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.6,0.2,0.8,0
		c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.5,0.8-1.1,1.2-1.6c0.2-0.2,0.4-0.4,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.4,0.2
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.7c0,1.6,0,3.2,0,4.8c0,0.2,0,0.4,0,0.5c0,0.1-0.1,0.2-0.1,0.3
		c-0.2,0.2-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0-0.3,0-0.5c0-1.1,0-2.3,0-3.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.3
		c0-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.4,0.5-0.8,1-1.1,1.5c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-0.9-1.1-1.3-1.7c-0.1-0.1-0.1-0.2-0.3-0.3c-0.1-0.1-0.2,0-0.2,0.1
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,1.3,0,2.6,0,3.9c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.4,0.2-0.5,0
		c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0-0.3C104.8,51.3,104.8,50.4,104.8,49.4z"/>
	<path d="M84.1,49c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.5,0.1-0.7
		c-0.1-0.7-0.4-1.2-1.1-1.5c-0.2-0.1-0.4-0.1-0.6-0.1h-1.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.4-0.7,0.6c0,0.1,0,0.3,0,0.4
		c0,0.9,0,1.7,0,2.6c0,0.9,0,1.8,0,2.6c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.3,0.3,0.6,0.3c0.5,0,1.1,0,1.6,0
		c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.7-0.1c0.4-0.1,0.7-0.3,0.9-0.6C85,51.4,85,49.9,84.1,49z M80.9,47.2c0-0.3,0.3-0.7,0.7-0.7
		c0.2,0,0.4,0,0.7,0c0.3,0,0.7,0.3,0.8,0.8c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0
		c-0.3,0-0.7-0.3-0.7-0.7C80.9,47.4,80.9,47.3,80.9,47.2z M83,52.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.4,0-0.7,0
		c-0.4,0-0.7-0.3-0.7-0.7c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6h0c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
		c0.2,0,0.4,0,0.7,0c0.7,0,1.2,0.4,1.4,1C84,50.6,83.8,51.6,83,52.1z"/>
	<path d="M71.6,49c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.2,0.1-0.4,0.1-0.7c-0.1-0.7-0.4-1.2-1.1-1.4c-0.2-0.1-0.3-0.1-0.5-0.1c-0.6,0-1.1,0-1.7,0c0,0-0.1,0-0.1,0
		c-0.4,0-0.7,0.4-0.7,0.6c0,0.2,0,0.3,0,0.5c0,0.9,0,1.7,0,2.6c0,0.9,0,1.7,0,2.6c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.4,0.3,0.6
		c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,1,0,1.5,0c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.1,1.1-0.4c0.4-0.3,0.7-0.6,0.9-1c0.2-0.3,0.3-0.7,0.3-1
		C72.2,50.1,72,49.5,71.6,49z M68.4,47.2c0-0.3,0.3-0.7,0.7-0.7c0.2,0,0.4,0,0.7,0c0.3,0,0.7,0.3,0.8,0.7c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0c-0.4,0-0.7-0.4-0.7-0.7C68.4,47.4,68.4,47.3,68.4,47.2z
		 M70.2,52.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.7,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6h0
		c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.7,0c0.6,0,1,0.3,1.3,0.7
		C71.5,50.5,71.3,51.8,70.2,52.2z"/>
	<path d="M120.2,48.8c-0.3-1.6-1.5-2.6-2.4-2.9c-0.5-0.2-1.1-0.2-1.7-0.2c-0.4,0-0.7,0.1-1,0.2c-1,0.4-2.2,1.5-2.3,3
		c-0.1,0.6,0,1.1,0.2,1.7c0.3,0.8,0.8,1.4,1.4,1.8c0.6,0.5,1.4,0.7,2.2,0.6c0.4,0,0.9-0.1,1.3-0.2C119.5,52.3,120.5,50.6,120.2,48.8
		z M117.6,52.1c-1,0.4-1.9,0.3-2.7-0.3c-0.5-0.4-0.9-0.9-1.1-1.5c-0.3-1.3,0.1-2.6,1.3-3.3c0.9-0.5,1.9-0.5,2.8,0
		c0.7,0.4,1.1,1,1.3,1.8c0.1,0.2,0.1,0.5,0.1,0.6C119.3,50.6,118.7,51.7,117.6,52.1z"/>
	<path d="M125.5,52.3c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.1-0.5,0.2-0.7c0.2-0.1,0.3-0.3,0.5-0.4
		c0.4-0.5,0.7-1,0.7-1.7c0-1.2-1-2.3-2.2-2.4c-0.5,0-1,0-1.5,0c-0.4,0-0.7,0.3-0.7,0.7c0,0.1,0,0.3,0,0.4c0,0.9,0,1.7,0,2.6
		c0,0.9,0,1.7,0,2.6c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.1,0.5,0c0,0,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.3,0.4,0.4
		c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2
		c0-0.1,0-0.2-0.1-0.3C125.6,52.5,125.6,52.4,125.5,52.3z M124.5,49.5c-0.3,0.3-0.6,0.4-1,0.4c-0.1,0-0.3,0-0.4,0
		c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.3h0c0-0.3,0-0.6,0-0.9c0-0.2,0-0.3,0.1-0.5
		c0-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.3,0,0.5,0,0.8,0c0.6,0.1,1.2,0.6,1.3,1.4C125.1,48.6,124.9,49.1,124.5,49.5z"/>
	<path d="M97.1,53.1c-0.7,0-1.2-0.2-1.7-0.7c-0.7-0.8-0.7-2-0.3-2.7c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.2-0.3,0.1-0.6
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0-0.2,0.1-0.3c0.2-0.6,0.8-1.1,1.4-1.1c0.1,0,0.3,0,0.4,0
		c0.5,0.1,0.9,0.3,1.2,0.8c0.1,0.2,0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.1-0.4,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.3-0.3-0.8-0.3-1-0.1c-0.3,0.3-0.4,0.8-0.2,1.1c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0.1
		c0.2,0,0.3,0.2,0.3,0.4c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-0.4,0-0.6,0.1c-0.3,0.1-0.6,0.3-0.9,0.5
		c-0.4,0.4-0.5,1-0.3,1.5c0.2,0.6,0.6,0.9,1.2,1c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.1-0.2,0-0.3
		c0,0,0-0.1-0.1-0.1c-0.2-0.1-0.3-0.3-0.5-0.4c0,0-0.1-0.1-0.2,0c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
		c0,0.2,0,0.4,0,0.6c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.5-0.2-0.8-0.8-0.5-1.3c0.3-0.4,0.8-0.6,1.2-0.4
		c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.3,0.1,0.4-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0.2-0.2,0.3-0.1c0.2,0.1,0.4,0.2,0.6,0.4
		c0.4,0.4,0.4,1.1-0.1,1.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
		C98.3,52.9,97.7,53.1,97.1,53.1z"/>
	<path d="M78.8,52.6c0-0.2-0.1-0.4-0.2-0.5c-0.6-1.8-1.3-3.6-1.9-5.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.4-0.4-0.5
		c-0.2-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,1-0.8,2.1-1.2,3.2c-0.3,0.8-0.6,1.6-0.9,2.4
		c0,0.1-0.1,0.3-0.1,0.5c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.4-0.4,0.5-0.6
		c0.2-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.5-0.6,1-0.7c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0.1,0.5,0.1
		c0.3,0.1,0.4,0.3,0.5,0.6c0,0.1,0,0.1,0.1,0.2c0.2,0.4,0.3,0.8,0.5,1.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.3,0.4,0.4
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.1C78.8,52.9,78.9,52.8,78.8,52.6z M76.4,49.1c-0.1,0.1-0.2,0.2-0.4,0.2
		c-0.3,0-0.5-0.3-0.4-0.6c0.1-0.3,0.2-0.5,0.3-0.7c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.2,0c0,0,0.1,0.1,0.1,0.1
		c0.1,0.2,0.2,0.5,0.3,0.7C76.5,48.8,76.5,48.9,76.4,49.1z"/>
	<path d="M56.2,49.4c0-0.9,0-1.8,0-2.6c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.2,0.5,0c0.1,0.1,0.2,0.3,0.2,0.5
		c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,1c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0.3-0.4,0.6-0.8,0.9-1.2c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.2,0.2
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.5,0.8-0.8,1.1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.2,0.5-0.2,0.8
		c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.2,0.2,0.2c0.7,1,1.3,1.9,2,2.9c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.1,0.1,0.2
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.1-0.2-0.2-0.2c-0.5-0.7-1-1.4-1.5-2.1
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.4-0.2-0.5,0.1c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.2,0,0.4,0,0.5
		c0,0.4,0,0.8,0,1.3c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3
		c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.3,0-0.7,0-1C56.2,50.7,56.2,50,56.2,49.4z"/>
	<path d="M127.3,49.4c0-0.9,0-1.7,0-2.6c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0
		c0.8,0,1.5,0,2.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3,0-0.4,0
		c-0.4,0-0.8,0-1.1,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.3-0.8,0.6c0,0,0,0.1,0,0.1c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.4,0.3,0.6
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0,0.4
		c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3,0-0.4,0c-0.4,0-0.9,0-1.3,0c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.2,0,1,0,1.2
		c0.1,0.3,0.3,0.6,0.7,0.6c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.2,0.3,0,0.5
		c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0-0.3,0c-0.8,0-1.5,0-2.3,0c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.3-0.8-0.7c0-0.1,0-0.2,0-0.3
		C127.3,51.2,127.3,50.3,127.3,49.4z"/>
	<path d="M62.1,49.4c0-0.9,0-1.7,0-2.6c0-0.1,0-0.3,0-0.4c0-0.3,0.3-0.7,0.7-0.7c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0,2.1,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3,0-0.4,0c-0.4,0-0.8,0-1.3,0
		c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.6,0.4-0.6,0.7c0,0.3,0,0.6,0,1c0,0.3,0.3,0.7,0.6,0.7c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.2,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.2,0
		c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.4-0.7,0.7c0,0.3,0,0.7,0,1c0,0.3,0.3,0.7,0.6,0.7c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.2,0.1
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.7,0-1.5,0-2.2,0c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.4-0.7-0.7c0-0.1,0-0.3,0-0.4
		C62.1,51.1,62.1,50.3,62.1,49.4z"/>
	<path class="st3" d="M134.8,16.8c0.2,0,0.4,0,0.5,0c0.6,0.1,1,0.5,1.4,1c0.3,0.5,0.3,1.1,0,1.7c-0.3,0.6-0.9,1-1.6,1.1
		c-0.4,0-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.6-1.2-1.1c-0.2-0.6-0.2-1.1,0.1-1.6c0.4-0.6,1-0.9,1.7-1C134.7,16.8,134.7,16.8,134.8,16.8z"
		/>
	<path d="M88,53.1c-0.9,0-1.5-0.4-2-1.2c-0.1-0.2-0.2-0.4-0.1-0.7c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.4,0
		c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.5,0.7,1,0.8c0.2,0,0.3,0,0.5,0c0.7-0.1,1.1-0.7,1.1-1.4
		c0-0.4-0.1-0.8-0.5-1.1c-0.3-0.3-0.6-0.5-1-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.5-0.4-0.6-1-0.5-1.6
		c0.1-0.6,0.5-1,1.1-1.2c0.7-0.2,1.4,0,1.9,0.6c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.4,0.2-0.5,0.2
		c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.7,0.3-0.7,0.6c-0.1,0.4,0,0.7,0.3,1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.2,0.7,0.4,1,0.7c1.2,1.2,0.6,3.2-1,3.7C88.4,53.1,88.2,53.1,88,53.1z"/>
	<path d="M127.7,12.2c-0.1,0-0.2,0-0.2,0c-1.6,0-3.2,0-4.8,0c-0.3,0-0.7,0-1,0c-0.1,0-0.2,0-0.1-0.1c0-0.1,0-0.1,0-0.2
		c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.1-0.3,0.4-0.3c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.1,0c0.8,0,1.6,0,2.4,0c0.8,0,1.7,0,2.5,0.1
		c0.5,0,1.1,0,1.6,0.1c0.3,0,0.6,0.1,0.9,0.2c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.7,0.2
		c-0.5,0.1-1,0.2-1.5,0.2C128.6,12.1,128.2,12.1,127.7,12.2z"/>
	<path d="M108.7,10.9c-0.2,0.3-0.3,0.6-0.5,0.9c-0.1,0.1-0.1,0.3-0.2,0.4c0,0-0.1,0-0.1,0c-1.3,0-2.6,0-3.9,0c0-0.3-0.1-0.6-0.1-0.9
		c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0.1-0.1c0.3,0,0.6,0,0.9,0c0.1,0,0.3,0,0.4,0c1.1,0,2.2,0,3.3,0C108.5,10.8,108.5,10.8,108.7,10.9
		C108.6,10.8,108.7,10.9,108.7,10.9z"/>
	<path d="M89.9,10.7c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7c0,0.3,0,0.3-0.3,0.3c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0
		c-0.6,0-1.2,0-1.8,0c0-0.5-0.1-0.9-0.3-1.4c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.5,0
		C88.3,10.7,89.1,10.7,89.9,10.7z"/>
</g>
</svg>

`,
];
