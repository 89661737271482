import React, { Component } from "react";
import moment from "moment";
import { Table } from "reactstrap";
import get from "lodash.get";
import { inrFormat } from  "./common/helper";
import isEmpty from "lodash.isempty";
import api from "../api/api"
import { withRouter } from "react-router-dom";
class OrderPrinting extends Component {
  constructor(props) {
    super(props);
    this.state = {
        orderData:[]
    };
  }

  componentDidMount(){
      
    api.getOrderByID({order_id:this.props.match.params.id}).then((response)=>{
        if(response.code===200){
            this.setState({
                orderData:response.data,
            })
        }
    })
  }

  render() {
   const {
      order_id,
      customer,
      order_food_details,
      total_quantity,
      sub_total,
      total_discount,
      tax1,
      tax2,
      grand_total,
      is_cod,
      payment_id,
    } = this.state.orderData;



    return (
      <div>
        {!isEmpty(this.state.orderData) && <div className="order-final-bill">
          <div className="bill-header"> cottagegrill.in</div>
          <div className="bill-title">Cottage Grill Kebabs & More</div>
          <div className="bill-address">
            Desai Niwas, Nr. Portugese Church, Dadar(West)
          </div>
          <div className="bill-mob">
            Mob : 9967940950
            <br />
            GST NO : 27AEJPD5737Q1ZW
          </div>
          <div className="bill-name">
            Name: {customer.cust_name}
            <br />
            Mobile: {customer.cust_mobile}
            <br />
            Address: {`${customer.address}, ${customer.landmark}, ${customer.pincode}`}
            <br />
            Payment Type: {`${is_cod ? "Cash On Delivery" : "Online"}`}
            {!is_cod && <div>Payment Id: {payment_id}</div>}
          </div>
          <div className>
            Date : {moment().format("MM-DD-YYYY, hh:mm:ss")}
            <br />
            Order No : {order_id}
          </div>
          <Table borderless>
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order_food_details.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{`${item.food_item.fo_name} ${
                      isEmpty(item.serving_type) ? "" : `(${item.serving_type})`
                    }`}</td>
                    <td>{item.quantity}</td>
                    <td>{item.per_unit_cost}</td>
                    <td>{item.per_unit_cost * item.quantity}</td>
                  </tr>
                );
              })}
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  Total Qty : {total_quantity}{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  Sub Total{" "}
                </td>
                <td> {sub_total} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  Discount{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  {get(order_food_details[0], "offer") === null
                    ? 0
                    : order_food_details[0].offer.discount_per}
                </td>
                <td> {`(${total_discount})`} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  CGST{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  2.5%{" "}
                </td>
                <td> {tax2} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  SGST{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  2.5%{" "}
                </td>
                <td> {tax1} </td>
              </tr>
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
              <tr className="grand-total">
                <td colspan="4" className="text-center">
                  {" "}
                  Grand Total{" "}
                </td>
                <td> {inrFormat(grand_total)}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>}
      </div>
    );
  }
}

export default withRouter(OrderPrinting);
