import * as network from "./network";

const getMasterSwitchStatus = () => {
  return network.get(`admin/masterswitch`);
};


const updateMasterSwitchStatus = (data = {}) => {
  return network.put(`admin/masterswitch`, data);
};

export default {
  getMasterSwitchStatus,
  updateMasterSwitchStatus,
};
