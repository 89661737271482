import * as network from "./network";

const fetchDishType = () => {
  return network.get(`admin/dishtype`);
};

const createDishType = (data = {}) => {
  return network.post(`admin/dishtype`, data);
};

const updateDishType = (data = {}) => {
  return network.put(`admin/dishtype`, data);
};

export default {
  fetchDishType,
  createDishType,
  updateDishType,
};
