import * as network from "./network";
import cuisine from "./cuisine";
import dishType from "./dishType";
import foodItem from "./foodItem";
import offer from "./offer";
import login from "./login";
import masterswitch from "./masterswitch";
import carousal from "./carousal";
import order from "./order";
import todayTotalSales from "./todayTotalSales";


export default {
  ...network,
  ...cuisine,
  ...dishType,
  ...foodItem,
  ...offer,
  ...login,
  ...masterswitch,
  ...carousal,
  ...order,
  ...todayTotalSales,
};
