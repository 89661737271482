

import * as network from "./network";

const fetchLast2DaysOrders = () => {
    return network.get(`admin/adminOrderDashboard`);
}

const updateOrder= (data = {}) =>{
    return network.put(`admin/adminOrderUpdate`,data);
}

const getFilteredOrders=(params)=>{
    
    return network.get(`admin/adminFilteredOrders`,params);
}

const getOrderByID=(params)=>{
    
    return network.get(`admin/orderbyid`,params);
}

export default{
    fetchLast2DaysOrders,
    updateOrder,
    getFilteredOrders,
    getOrderByID
}