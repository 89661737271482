import * as network from "./network";

const fetchCuisine = () => {
  return network.get(`admin/cuisine`);
};
const createCuisine = (data = {}) => {
  return network.post(`admin/cuisine`, data);
};

const updateCuisine = (data = {}) => {
  return network.put(`admin/cuisine`, data);
};

export default {
  fetchCuisine,
  createCuisine,
  updateCuisine,
};
