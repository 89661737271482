import React, { Component } from "react";
import {
  
  Route,
  Switch,
  HashRouter as Router,
  
} from "react-router-dom";
import "./scss/style.scss";

import isEmpty from "lodash.isempty";
import OrderPrinting from "./components/OrderPrinting";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
//const Login = React.lazy(() => import("./views/pages/login/Login"));
const Login = React.lazy(() => import("./components/Login"));

class App extends Component {
  render() {
    return (
      //  <Router>
      //   <Route exact path="/" component={Landing} />
      // </Router>

      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            {/* <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            /> */}
{!isEmpty(localStorage.getItem("token")) && <Route
        exact path="/order/:id" component={OrderPrinting} />}
            {isEmpty(localStorage.getItem("token")) ? (
              <Login />
            ) : (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            )}
         
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
