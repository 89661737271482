import * as network from "./network";

const fetchFoodItem = () => {
  return network.get(`admin/fooditem`);
};

const createFoodItem = (data = {}) => {
  return network.post(`admin/fooditem`, data);
};

const updateFoodItem = (data = {}) => {
  return network.put(`admin/fooditem`, data);
};

export default {
  fetchFoodItem,
  createFoodItem,
  updateFoodItem,
};
